import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./dentist.module.scss";

export default (props) => {

	const {
		data: {
			dentist,
			dentist: {
				id: dentistId,
				title,
				ACFDentist: {
					headshot,
					dentistInfo: {
						left: {
							specialitiesHeading,
							specialities,
							insurancesHeading,
							insurances,
							featuredServicesHeading,
							featuredServices
						},
						right: {
							contentHeading,
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							dentistContent: content,
						}
					}
				},
			},
			locations: {
				nodes: dentistLocations
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}
			},
			introBGTablet,
			introBGDesktop,
			introBGXL,
			plaqusterDougie,
			plaqusterRojo,
			rightArrowYellow,
			teamPage,
		}
	} = props;

	const locations = dentistLocations.filter((location, index) => {

		if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.left.dentists ){
			const dentistIDs = location.ACFLocation.locationInfo.left.dentists.map(ortho =>
				`${ortho.id}`,
			);

			return dentistIDs && dentistIDs.includes(dentistId);
		} else {
			return null;
		}
	});

	return (
		<Layout hideCtaButton={true}>
			<Seo post={dentist} />  
			<div className={`${styles.top} hero`}>
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }


				<div className={styles.dougie}>
					<FluidImage image={plaqusterDougie} loading={"eager"} fadeIn={false} alternativeText={'Dougie, the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.rojo}>
					<FluidImage image={plaqusterRojo} loading={"eager"} fadeIn={false} alternativeText={'Rojo the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.left}>
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }

					<div className={styles.mobilebreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styles.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styles.locationname}>{title}</span></div>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styles.apptlink} passedIconClass={styles.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>
				{ introLink && <WPLink link={introLink} passedClass={styles.link} passedIconClass={styles.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }

				<div className={styles.right}>
					<div className={styles.lefttop}>
						<div className={styles.profilewrap}>
							{/* <div className={styles.desktopbreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styles.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styles.locationname}>{title}</span></div> */}
							<div className={styles.dentistinfo}>
								<h1 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h1>
								{ locations && locations.map( (location, index) => {
									return <Link key={location.id} className={styles.locationlink} to={location.link} dangerouslySetInnerHTML={{__html: location.title}} />
								})}
							</div>
							<div className={styles.dentistcontainer}>
								{ headshot && <FluidImage image={headshot} passedClass={styles.image} loading={"eager"} /> }
							</div>
						</div>
						<div className={styles.doctorinfo}>
							<div className={styles.specialties} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								<span className={styles.heading}>{ specialitiesHeading ? specialitiesHeading : "Specialities" }</span>
								<ul className={styles.list}>
									{ specialities && specialities.map((service, index) => {
										return (
											<li key={`lstsrvc-'${service.id}`} className={styles.listitem}>
												<Link to={service.link} className={styles.specialtylink}>
													<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" className={styles.icon}>
														<g fill="none" fillRule="evenodd">
															<g fill="#FBB03B" className={styles.iconarrow}>
																<g>
																	<g>
																		<g>
																			<path d="M12.266 226.266L13 227 16.5 223.5 13 220 12.266 220.734 14.505 222.974 9.5 222.974 9.5 224.026 14.505 224.026z" transform="translate(-1024 -1130) translate(1015 215) translate(0 629) translate(0 66)"/>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</svg>
													<span className={styles.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
												</Link>	
											</li>
										)
									})}
								</ul>
							</div>

							<div className={styles.insurancelogos} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								<span className={styles.heading}>{ insurancesHeading ? insurancesHeading : "We accept the following insurance" }</span>
								<div className={styles.insurancebottom}>
									{ insurances && insurances.map( (insurance, index) => {
										const image = insurance.ACFInsurance.logo;
										return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
									})}
								</div>
							</div>

						</div>
						
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
			
			<div className={styles.left}>
				<div className={styles.dentistcontent}>
					<div className={styles.contentleft}>
						<span className={styles.gettoknow}>{contentHeading ? contentHeading : `Get to know ${title}.` }</span>
						{ content && <div className={`wpcontent ${styles.dentistwpcontent}`} dangerouslySetInnerHTML={{__html: content}}></div> }
						<h3 className={styles.ftservicesheading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{ featuredServicesHeading ? featuredServicesHeading : "Featured Services" }</h3>
						<div className={styles.servicescontainer} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{ featuredServices && featuredServices.map((service, _index) => {
								return (
									<div key={`ftsrvc-'${service.id}`} className={styles.featuredservice}>
										<Link to={service.link} className={styles.title}><h4 className={styles.titlewrap}>{service.title} <FixedImage image={rightArrowYellow} passedClass={styles.icon} /></h4>
											{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
										</Link>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className={styles.right}></div>
			</div>
		</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		dentist: wpDentist(id: { eq: $id }) {
			id
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			ACFDentist {
				headshot {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				introBGTablet: headshot {
					localFile {
						childImageSharp {
							fluid(maxWidth: 560){
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				introBGDesktop: headshot {
					localFile {
						childImageSharp {
							fluid(maxWidth: 840){
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				introBGXL: headshot {
					localFile {
						childImageSharp {
							fluid(maxWidth: 1200) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				dentistInfo {
					right {
						introContent {
							blurb
							heading
							link {
								target
								title
								url
							}
						}
						contentHeading
						dentistContent
					}
					left {
						specialitiesHeading
						specialities {
							... on WpService {
								id
								title
								link
							}
						}
						insurancesHeading
					  	insurances {
							... on WpInsurance {
						  		id
								ACFInsurance {
									logo {
										altText
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp
												}
											}
											publicURL
                							extension
										}
									}
								}
							}
					  	}
						featuredServicesHeading
						featuredServices {
							... on WpService {
								id
								title
								link
								ACFService {
									briefDescription
									serviceIcon {
										altText
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp
												}
											}
											publicURL
                							extension
										}
									}
								}
							}
						}
					}
				}
			}
		}
		locations: allWpLocation {
			nodes {
			  title
			  id
			  link
			  ACFLocation {
				locationInfo {
				  left {
					dentists {
					  ... on WpDentist {
						id
					  }
					}
				  }
				}
			  }
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		plaqusterDougie: file(name: {eq: "dougie"}) {
			childImageSharp {
				fluid(maxWidth: 140){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		plaqusterRojo: file(name: {eq: "rojo"}) {
			childImageSharp {
				fluid(maxWidth: 160){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
			nodes {
			  link
			}
		}
	}
`;
